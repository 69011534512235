import { useState, useContext } from "react";
import { type ReactElement } from "react";

import { Link } from "@remix-run/react";

import CloseIcon from "@mui/icons-material/CloseRounded";
import MenuIcon from "@mui/icons-material/MenuRounded";
import {
  AppBar,
  Box,
  Container,
  Drawer,
  FormControl,
  IconButton,
  Toolbar,
  keyframes,
  Stack,
  Button,
} from "@mui/material";

import { aboutUsLinks, partnerLinksHeader, serviceLinks } from "../links";
import safeLink from "../safeLink";
import { ConnectButton } from "./Buttons";
import { DropDownMenu } from "./DropdownMenu";
import { ExpendableMenu } from "./ExpendableMenu";
import { BurgerMenuLogo, HeaderLogo, KeyHeaderLogo } from "./Logo";
import { BackgroundProvider } from "./background";
import { IntersectionContext } from "./useIntersection";

const fadeInDown = keyframes`
  0% {
    opacity: 0;
    transform: translate3d(0,-100%,0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
`;

const defaultButton = (
  <Button variant="contained" size="small" {...safeLink("/formulaire")}>
    Proposez votre logement
  </Button>
);

export const Header: React.FC<{
  contrast?: boolean;
  static?: boolean;
  cta?: ReactElement;
}> = ({ contrast = false, cta = defaultButton, static: isStatic = false }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const isVisible = useContext(IntersectionContext)?.isVisible;

  return (
    <BackgroundProvider
      value={isVisible !== false && contrast ? "contrast" : "light"}
    >
      <AppBar
        position={isVisible !== true ? "fixed" : "absolute"}
        component="header"
        color="transparent"
        elevation={0}
        sx={{
          animation: isVisible === false ? `${fadeInDown} 1s` : "none",
        }}
      >
        <Toolbar
          disableGutters
          sx={{
            bgcolor: isStatic
              ? "white"
              : isVisible !== false
              ? "transparent"
              : "white",
          }}
        >
          <Container>
            <Stack
              direction="row"
              flexGrow="1"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
            >
              <Stack direction="row" alignItems="center">
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={() => setMobileOpen(true)}
                  sx={{ display: { sm: "none" } }}
                >
                  <MenuIcon
                    sx={(theme) => ({
                      color:
                        isVisible !== false && contrast
                          ? theme.palette.common.white
                          : "secondary",
                    })}
                    fontSize="large"
                  />
                </IconButton>
                <Box pr={1}>
                  <Link to="/">
                    <KeyHeaderLogo />
                    <HeaderLogo />
                  </Link>
                </Box>
              </Stack>
              <Box display={{ sm: "flex", xs: "none" }}>
                <DropDownMenu title="Services" links={serviceLinks} />
                <DropDownMenu title="À propos" links={aboutUsLinks} />
                <DropDownMenu title="Partenaires" links={partnerLinksHeader} />
              </Box>
              {isVisible === false && cta ? (
                cta
              ) : (
                <FormControl>
                  <ConnectButton
                    variant="contained"
                    size="small"
                    href="https://app.leazly.fr/auth/login"
                  >
                    Se connecter
                  </ConnectButton>
                </FormControl>
              )}
            </Stack>
          </Container>
        </Toolbar>
      </AppBar>
      <Drawer open={mobileOpen} onClose={() => setMobileOpen(false)}>
        <Stack direction="column" p={2}>
          <Stack direction="row" flexWrap="nowrap">
            <BurgerMenuLogo />
            <IconButton
              sx={{ pl: 4 }}
              onClick={() => setMobileOpen(false)}
              onTouchStart={() => setMobileOpen(false)}
            >
              <CloseIcon fontSize="medium" />
            </IconButton>
          </Stack>
          <Box pt={2}>
            <ExpendableMenu title="Services" links={serviceLinks} />
            <ExpendableMenu title="À propos" links={aboutUsLinks} />
            <ExpendableMenu title="Partenaires" links={partnerLinksHeader} />
          </Box>
        </Stack>
      </Drawer>
    </BackgroundProvider>
  );
};
