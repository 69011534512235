import { useState } from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box, Stack, Link } from "@mui/material";

import { type LinkProperties } from "../links";
import safeLink from "../safeLink";
import { DropDownButton } from "./Buttons";

export const DropDownMenu: React.FC<{
  title: string;
  links: LinkProperties[];
}> = ({ title, links }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const id = "basic-menu-" + title.toLowerCase().replaceAll(" ", "-");

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    const menu: DOMRect | undefined = document
      .getElementById(id)
      ?.getBoundingClientRect();
    if (!menu) {
      setAnchorEl(null);
      return;
    }

    const menuBoundary = {
      left: Math.ceil(menu.left || 0),
      top: Math.floor(menu.top || 0),
      right: Math.floor(menu.right || 0),
      bottom: Math.floor(menu.bottom || 0),
    };

    if (
      e.clientX > menuBoundary.left &&
      e.clientX < menuBoundary.right &&
      e.clientY < menuBoundary.bottom &&
      e.clientY >= menuBoundary.top
    ) {
      return;
    }

    setAnchorEl(null);
  };
  return (
    <Box position="relative">
      <DropDownButton
        id="basic-button"
        aria-controls={open ? id : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onMouseOver={handleClick}
        onMouseLeave={handleClose}
        endIcon={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        sx={{ whiteSpace: "nowrap" }}
      >
        {title}
      </DropDownButton>
      <Stack
        bgcolor="rgba(0, 0, 0, 0.45)"
        id={id}
        position="absolute"
        top="100%"
        left={0}
        display={open ? "initial" : "none"}
        onMouseLeave={handleClose}
        borderRadius={1}
        alignItems="stretch"
        border="none"
      >
        {links.map((item, key) => (
          <Box
            key={key}
            sx={(theme) => ({
              color: theme.palette.common.white,
              "&:hover": {
                backgroundColor: theme.palette.common.white,
                color: theme.palette.common.black,
              },
              ...(key === 0
                ? {
                    borderTopLeftRadius: theme.shape.borderRadius,
                    borderTopRightRadius: theme.shape.borderRadius,
                  }
                : key === links.length - 1
                ? {
                    borderBottomLeftRadius: theme.shape.borderRadius,
                    borderBottomRightRadius: theme.shape.borderRadius,
                  }
                : {}),
            })}
            onClick={() => setAnchorEl(null)}
          >
            <Link
              underline="none"
              color="inherit"
              whiteSpace="nowrap"
              display="block"
              py={1}
              px={2}
              {...safeLink(item.href)}
            >
              {item.label}
            </Link>
          </Box>
        ))}
      </Stack>
    </Box>
  );
};
