import { Button, styled } from "@mui/material";

import {
  type HeaderBackgroundVariant,
  withBackground,
  shouldForwardProp,
} from "./background";

const _DropDownButton = styled(Button, {
  shouldForwardProp,
})<HeaderBackgroundVariant>(({ background, theme }) =>
  background === "contrast"
    ? {
        color: theme.palette.common.white,
        ":hover": {
          opacity: 0.7,
          backgroundColor: "transparent",
        },
      }
    : {
        color: theme.palette.common.black,
        ":hover": {
          opacity: 0.7,
          backgroundColor: "transparent",
        },
      },
);

export const DropDownButton = withBackground(_DropDownButton);

const _ConnectButton = styled(Button, {
  shouldForwardProp,
})<HeaderBackgroundVariant>(
  ({ background, color }) =>
    background === "contrast" && {
      backgroundColor: "rgba(255, 255, 255, 0.2)",
      ":hover": {
        backgroundColor: "rgba(255, 255, 255, 0.4)",
      },
    },
);

export const ConnectButton = withBackground(_ConnectButton);
