import { useState } from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box, Button, Collapse, Link, Typography } from "@mui/material";
import { Stack } from "@mui/system";

import { type LinkProperties } from "../links";
import safeLink from "../safeLink";

export const ExpendableMenu: React.FC<{
  title: string;
  links: LinkProperties[];
}> = ({ title, links }) => {
  const [open, setOpen] = useState(false);
  const onChange = () => {
    setOpen(!open);
  };
  return (
    <Box>
      <Box
        bgcolor={(theme) => (open ? theme.palette.grey[100] : "transparent")}
      >
        <Button
          id="basic-button"
          sx={(theme) => ({
            color: theme.palette.common.black,
            bgcolor: "transparent",
            ":hover": {
              bgcolor: "transparent",
              opacity: 0.7,
            },
          })}
          onClick={onChange}
          endIcon={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        >
          {title}
        </Button>
      </Box>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Stack direction="column" pl={2}>
          {links.map((link, key) => (
            <Link
              py={1}
              key={key}
              underline="none"
              color="inherit"
              whiteSpace="nowrap"
              {...safeLink(link.href)}
            >
              <Typography variant="body1">{link.label}</Typography>
            </Link>
          ))}
        </Stack>
      </Collapse>
    </Box>
  );
};
